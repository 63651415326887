<template>
  <div class="card card-padding">
    <div class="d-flex justify-content-between align-items-center">
      <router-link class="backBtn" :to="'/business/feedback/' + (editType === BusinessIntentsTypeEnum.REQUEST ? 'requests' : 'ideas')">
        <img src="/media/buying/icons/general/arr074.svg" alt="">
        {{ $t("Cancel") }}
      </router-link>
      <el-radio-group
        class="radioTab"
        v-model="editType"
        @change="handleTypeChange"
      >
        <el-radio-button :label="'request'" name="Request">{{$t("Request")}}</el-radio-button>
        <el-radio-button :label="'idea'" name="Ideas">{{ $t("Idea") }}</el-radio-button>
      </el-radio-group>
    </div>

    <CreateRequestOrIdeaForm
      v-if="showForm"
      :type="editType"
      :dataForUpdate="data"
      :buttonIndicator="buttonIndicator"
      @handle-save="updateRequestOrIdea"
    />
    <CustomLoader v-else height="400px" />
  </div>
</template>

<script>
  import store from "@/store";
  import CreateRequestOrIdeaForm from "@/buying-teams/shared-components/request-idea/CreateRequestOrIdeaForm";
  import CustomLoader from "../utils/CustomLoader";
  import { redirectToRouteWithName } from "../../helpers/UrlHelper";
  import NotificationService from "../../services/NotificationService";
  import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
  import {diagLog} from "@/core/helpers/GlobalHelper";
  import {BusinessIntentsTypeEnum} from "@/store/enums/BusinessIntentsTypeEnum";

  export default {
    name: "EditRequestOrIdea",
    components: {
      CreateRequestOrIdeaForm,
      CustomLoader
    },

    props: [],

    data() {
      return {
        editType: this.$route.meta.editType,
        data: [],
        showForm: false,
        buttonIndicator: 'off',
        BusinessIntentsTypeEnum
      };
    },

    computed: {
      businessCountries() {
        return store.getters.businessCountries;
      },
      businessProductAreas() {
        return store.getters.businessProductAreas;
      }
    },
    watch: {
      editType(newType) {
        this.detectPageTitle(newType);
      },
      '$route': {
        handler() {
          if (this.$route.params.id) {
            this.showForm = false;
            this.detectPageTitle(this.editType);
            this.getRequest();
          }
        }
      }
    },

    async mounted() {
      this.detectPageTitle(this.editType);
      this.getRequest();
    },


    methods: {
      async getRequest() {
        this.loading = true;
        store.dispatch('getRequestDetails', +this.$route.params.id).then((res) => {
          this.data = res;
        }).finally(() => {
          this.showForm = true;
        })
      },
      async updateRequestOrIdea(data) {
        data.id = this.$route.params.id;
        this.editType === BusinessIntentsTypeEnum.REQUEST ? await this.updateRequest(data) : await this.updateIdea(data);
      },

      async updateRequest(data) {
        this.buttonIndicator = 'on'
        await store.dispatch("updateRequest", data)
          .then(res => {
            redirectToRouteWithName("business-requests");
          })
          .catch(error => {
            console.log("error", error);
            NotificationService.swalError(error);
          }).finally(() => {
            this.buttonIndicator = 'off';
          });
      },

      async updateIdea(data) {
        this.buttonIndicator = 'on'
        diagLog('button-loading', this.buttonIndicator);
        await store.dispatch("updateIdea", data)
          .then(res => {
            redirectToRouteWithName("business-ideas");
          })
          .catch(error => {
            console.log("error", error);
            NotificationService.swalError(error);
          }).finally(() => {
            this.buttonIndicator = 'off';
            diagLog('button-loading', this.buttonIndicator);
          });
      },

      detectPageTitle(editType) {
        if (editType === BusinessIntentsTypeEnum.REQUEST) {
          setCurrentPageBreadcrumbs({
            title: this.$t('Edit Request'),
            subTitle: this.$t('Requests for your Banks (action, change, other)')
          })
        } else {
          setCurrentPageBreadcrumbs({
            title: this.$t('Edit Idea'),
            subTitle: this.$t('Ideas for your Banks (improvements, developments, other)')
          })
        }
      },
      handleTypeChange() {
        this.$router.push(`/business/feedback/${this.editType}/${this.$route.params.id}/edit`)
      }
    }
  };
</script>

